import enrekang from "../../src/assets/bg/enrekangkab.jpg";
import luwu from "../../src/assets/bg/luwukab.jpg";
import gowa from "../../src/assets/bg/gowakab.jpg";
import wajo from "../../src/assets/bg/wajokab.jpg";
import mamasa from "../../src/assets/bg/mamasakab.jpg";
import konawe from "../../src/assets/bg/konawekab.webp";
import ambon from "../../src/assets/bg/ambon.png";
import ketapang from "../../src/assets/bg/ketapangkab.jpg";
import maskot from "../../src/assets/profil/maskot_digides.png";
import bupatiEnrekang from "../../src/assets/profil/bupati_enrekang.jpg";
import bupatiLuwu from "../../src/assets/profil/bupati_luwu.jpg";
import bupatiGowa from "../../src/assets/profil/bupati_gowa.jpg";
import bupatiWajo from "../../src/assets/profil/bupati_wajo.jpg";
import bupatiMamasa from "../../src/assets/profil/bupati_mamasa.jpg";
import bupatiKonawe from "../../src/assets/profil/bupati_konawe.jpg";
import sicesa from "../../src/assets/logo/sicesa.png";

export const STRINGS = {
  enrekangkab: {
    title: "PENDEKAR",
    desc: "Pemantauan Desa dan Kelurahan Terintegrasi",
    nama: "Desa Emas",
    kabkota: "Kabupaten Enrekang",
    slogan: "Tana Rigalla' Tana Riabussungi",
    logo_kab_url:
      "https://cdn.digitaldesa.com/uploads/landing/logo-desa/enrekang.png",
    logo_dss_url:
      "https://cdn.digitaldesa.com/statics/online/homepage/img/logo_desa_emas_enrekang.svg",
    bg_jumbotron: enrekang,
    nama_user: "Dr. H. Baba, SE., MM.",
    jabatan_user: "Pj. Bupati Enrekang",
    foto_profil: bupatiEnrekang,
    logo_hide: "",
    logo_first: "gap",
    background_color: "#3B2D64",
    font_color: "#ffffff",
    bg_color_jumbotron: "#642ee2",
    font_color_jumbotron: "#ffffff",
    logo_second_size: "enrekang",
    menu_pelayanan: "hide",
    menu_pengaduan: "hide",
    theme: "",
    alternative_style: "assets/css/style.css",
  },
  luwukab: {
    title: "PENDEKAR",
    desc: "Pemantauan Desa dan Kelurahan Terintegrasi",
    nama: "PENDEKAR",
    kabkota: "Kabupaten Luwu",
    slogan:
      "Luwu Wanua Mappatuo'e Naewai Alena, Toddo Puli Temmalara Te'bakke Tongengnge",
    logo_kab_url:
      "https://cdn.digitaldesa.com/uploads/landing/logo-desa/luwu.png",
    logo_dss_url: "",
    bg_jumbotron: luwu,
    nama_user: "Basmin Mattayang",
    jabatan_user: "Bupati Luwu",
    foto_profil: bupatiLuwu,
    logo_hide: "hide",
    logo_first: "",
    background_color: "#327A6E",
    font_color: "#ffffff",
    bg_color_jumbotron: "#327A6E",
    font_color_jumbotron: "#ffffff",
    logo_second_size: "",
    menu_pelayanan: "hide",
    menu_pengaduan: "hide",
    theme: "",
    alternative_style: "assets/css/alternative-style.css",
  },
  ketapangkab: {
    title: "SiCesa",
    desc: "Sistem Cepat Administrasi Desa",
    nama: "SiCesa",
    kabkota: "Kabupaten Ketapang",
    slogan:
      "Ramah, Senyum dan Sapa",
    logo_kab_url:
      "https://cdn.digitaldesa.com/uploads/landing/logo-desa/ketapangkab.png",
    logo_dss_url: sicesa,
    bg_jumbotron: ketapang,
    nama_user: "Martin Rantan, S.H.",
    jabatan_user: "Bupati Ketapang",
    foto_profil: maskot,
    logo_hide: "",
    logo_first: "gap",
    background_color: "#327A6E",
    font_color: "#ffffff",
    bg_color_jumbotron: "#327A6E",
    font_color_jumbotron: "#ffffff",
    logo_second_size: "",
    menu_pelayanan: "hide",
    menu_pengaduan: "hide",
    theme: "",
    alternative_style: "assets/css/alternative-style.css",
  },
  mamasakab: {
    title: "PENDEKAR",
    desc: "Pemantauan Desa dan Kelurahan Terintegrasi",
    nama: "PENDEKAR",
    kabkota: "Kabupaten Mamasa",
    slogan: "Mesa Kada Dipotuo, Pantan Kada Dipomate",
    logo_kab_url:
      "https://cdn.digitaldesa.com/uploads/landing/logo-desa/mamasakab.png",
    logo_dss_url: "",
    bg_jumbotron: mamasa,
    nama_user: "Ramlan Badawi",
    jabatan_user: "Bupati Mamasa",
    foto_profil: bupatiMamasa,
    logo_hide: "hide",
    logo_first: "",
    background_color: "#327A6E",
    font_color: "#ffffff",
    bg_color_jumbotron: "#327A6E",
    font_color_jumbotron: "#ffffff",
    logo_second_size: "",
    menu_pelayanan: "",
    menu_pengaduan: "hide",
    theme: "",
    alternative_style: "assets/css/alternative-style.css",
  },
  gowakab: {
    title: "PENDEKAR",
    desc: "Pemantauan Desa dan Kelurahan Terintegrasi",
    nama: "PENDEKAR",
    kabkota: "Kabupaten Gowa",
    slogan: "Butta Patturioloang",
    logo_kab_url:
      "https://cdn.digitaldesa.com/uploads/landing/logo-desa/gowakab.png",
    logo_dss_url: "",
    bg_jumbotron: gowa,
    nama_user: "Adnan Purichta Ichsan",
    jabatan_user: "Bupati Gowa",
    foto_profil: bupatiGowa,
    logo_hide: "hide",
    logo_first: "",
    background_color: "#327A6E",
    font_color: "#ffffff",
    bg_color_jumbotron: "#327A6E",
    font_color_jumbotron: "#ffffff",
    logo_second_size: "",
    menu_pelayanan: "hide",
    menu_pengaduan: "hide",
    theme: "",
    alternative_style: "assets/css/alternative-style.css",
  },
  wajokab: {
    title: "PENDEKAR",
    desc: "Pemantauan Desa dan Kelurahan Terintegrasi",
    nama: "Mabello",
    kabkota: "Kabupaten Wajo",
    slogan: "Maradeka Towajoe Ade'na Napopuang",
    logo_kab_url:
      "https://cdn.digitaldesa.com/uploads/landing/logo-desa/wajokab.png",
    logo_dss_url:
      "https://i.postimg.cc/XYfS3Cfp/Logo-Mabello1-removebg-preview.png",
    bg_jumbotron: wajo,
    nama_user: "Amran Mahmud",
    jabatan_user: "Bupati Wajo",
    foto_profil: bupatiWajo,
    logo_hide: "",
    logo_first: "gap",
    background_color: "#327A6E",
    font_color: "#ffffff",
    bg_color_jumbotron: "#327A6E",
    font_color_jumbotron: "#ffffff",
    logo_second_size: "",
    menu_pelayanan: "hide",
    menu_pengaduan: "hide",
    theme: "alternative",
    alternative_style: "assets/css/alternative-style.css",
  },
  konawekab: {
    title: "SIMONI ADIK BANGDES",
    desc: "Sistem Informasi Monitoring Administrasi Kinerja Pembangunan Desa",
    nama: "PENDEKAR",
    kabkota: "Kabupaten Konawe",
    slogan: "Berdaya saing, sejahtera, adil, dan berkelanjutan (Konawe Bersahaja)",
    logo_kab_url:
      "https://cdn.digitaldesa.com/uploads/landing/logo-desa/konawekab.png",
    logo_dss_url: "",
    bg_jumbotron: konawe,
    nama_user: "H. Yusran Akbar, S.T.",
    jabatan_user: "Bupati Konawe",
    foto_profil: maskot,
    logo_hide: "hide",
    logo_first: "",
    background_color: "#327A6E",
    font_color: "#ffffff",
    bg_color_jumbotron: "#327A6E",
    font_color_jumbotron: "#ffffff",
    logo_second_size: "",
    menu_pelayanan: "hide",
    menu_pengaduan: "",
    theme: "alternative",
    alternative_style: "assets/css/alternative-style.css",
  },
  ambon: {
    title: "PENDEKAR",
    desc: "Pemantauan Desa dan Kelurahan Terintegrasi",
    nama: "PENDEKAR",
    kabkota: "Kota Ambon",
    slogan: "Bersatu Manggurebe Maju",
    logo_kab_url:
      "https://cdn.digitaldesa.com/uploads/landing/logo-desa/ambon.png",
    logo_dss_url: "",
    bg_jumbotron: ambon,
    nama_user: "PENDEKAR",
    jabatan_user: "PJ.AMBON",
    foto_profil: maskot,
    logo_hide: "hide",
    logo_first: "",
    background_color: "#327A6E",
    font_color: "#ffffff",
    bg_color_jumbotron: "#327A6E",
    font_color_jumbotron: "#ffffff",
    logo_second_size: "",
    menu_pelayanan: "hide",
    menu_pengaduan: "hide",
    theme: "",
    alternative_style: "assets/css/alternative-style.css",
  },
  "baguala-ambon": {
    title: "PENDEKAR",
    desc: "Pemantauan Desa dan Kelurahan Terintegrasi",
    nama: "PENDEKAR",
    kabkota: "Kecamatan Baguala",
    slogan: "Bersatu Manggurebe Maju",
    logo_kab_url:
      "https://cdn.digitaldesa.com/uploads/landing/logo-desa/ambon.png",
    logo_dss_url: "",
    bg_jumbotron: ambon,
    nama_user: "KEC.BAGUALA",
    jabatan_user: "PJ.Kecamatan Baguala",
    foto_profil: maskot,
    logo_hide: "hide",
    logo_first: "",
    background_color: "#327A6E",
    font_color: "#ffffff",
    bg_color_jumbotron: "#327A6E",
    font_color_jumbotron: "#ffffff",
    logo_second_size: "",
    menu_pelayanan: "hide",
    menu_pengaduan: "hide",
    theme: "",
    alternative_style: "assets/css/alternative-style.css",
  },
  "wawotobi-konawekab": {
    title: "SIMONI ADIK BANGDES",
    desc: "Sistem Informasi Monitoring Administrasi Kinerja Pembangunan Desa",
    nama: "PENDEKAR",
    kabkota: "Kec. Wawotobi, Kab. Konawe",
    slogan: "Konawe Harapan Rakyat",
    logo_kab_url:
      "https://cdn.digitaldesa.com/uploads/landing/logo-desa/konawekab.png",
    logo_dss_url: "",
    bg_jumbotron: konawe,
    nama_user: "Harmin Ramba",
    jabatan_user: "Pj.Kec Wawotobi ",
    foto_profil: bupatiKonawe,
    logo_hide: "hide",
    logo_first: "",
    background_color: "#327A6E",
    font_color: "#ffffff",
    bg_color_jumbotron: "#327A6E",
    font_color_jumbotron: "#ffffff",
    logo_second_size: "",
    menu_pelayanan: "hide",
    menu_pengaduan: "",
    theme: "alternative",
    alternative_style: "assets/css/alternative-style.css",
  },
};
